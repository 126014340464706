import React from 'react'
import './header.css'
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';

export default function Header() {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
  
  return (
    <div className='headerWrapper'>
        <div className="headerContainer">
            <div className="logoContainer">
            <img src="/assets/ab.png" className='yunshulogo' alt="" />
            <h5>Albatrox Logistics</h5>
            </div>
            <div className="accountContainer">
                <p>{formattedDate}</p>
                <NotificationsIcon className='headerIcons' />
                <PersonIcon className='headerIcons' />
            </div>

            
        </div>
    </div>
  )
}
