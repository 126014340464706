import {React,useState,useEffect }from 'react'
import { Button,Modal,ModalHeader,Table } from 'reactstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'


export default function Users() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false)
    const [username,setUsername] = useState('');
    const [useremail, setUseremail] = useState('');
    const [userphone, setUserphone] = useState('');
    const [userrole,setUserrole] = useState('');
    const [password, setPassword] = useState('')
    const [users,setUsers] = useState([]);
    const [selectedUser, setSelecteduser] = useState('')


    const toggle = ()=>{
        setModal(!modal)
    }
    const toggle2 = (e)=>{
        const id = e.target.value;
        setSelecteduser(id)
        setModal2(!modal2)

        Axios.get(`https://albatroxmainapi.uuidesign.xyz/api/getThisuser?id=${id}`).then((res)=>{
            setUsername(res.data.username)
            setUseremail(res.data.useremail);
            setUserphone(res.data.userphone);
            setUserrole(res.data.role)
            setPassword(res.data.password);

        })
    }
    
    const toggle3 =()=>{
        setModal3(!modal3)
    }

    useEffect(()=>{
        Axios.get('https://albatroxmainapi.uuidesign.xyz/api/getAllusers').then((res)=>{
            setUsers(res.data)
        })
    },[])

    const submitUser = () =>{
        if(username !== '' && useremail !== '' && userphone !== '' && userrole !== '' &&  password !== ''){
            Axios.post('https://albatroxmainapi.uuidesign.xyz/api/postUser',{
                username,useremail,userphone,userrole,password
            }).then((res)=>{
                if(res.data.exist){
                    toast.error('User email already exists!')
                }
                else if(res.data.success){
                    Axios.get('https://albatroxmainapi.uuidesign.xyz/api/getAllusers').then((res)=>{
                        setUsers(res.data)
                    })
                    setModal(!modal);
                    toast.success('User has been added')
                }

            }).catch((err)=>{
                toast.error('Network connection error!')
            })
        }
        else{
            toast.error('All fields are requred!')
        }
    }

    const editUser = ()=>{
        Axios.put('https://albatroxmainapi.uuidesign.xyz/api/updateUser',{
            username,useremail,userphone,userrole,password,selectedUser
        }).then((res)=>{
            if(res.data.success){
                Axios.get('https://albatroxmainapi.uuidesign.xyz/api/getAllusers').then((res)=>{
                    setUsers(res.data)
                })
                toast.success('Changes have been saved!');
                setModal2(false);

            }
            
            else{
                toast.error('Something went wrong. Please contact support!')
            }
        }).catch((err)=>{
            toast.error('Network connection error!')
        })
    }

    const deleteUser = () =>{
        Axios.delete(`https://albatroxmainapi.uuidesign.xyz/api/deleteUser?id=${selectedUser}`).then((res)=>{
            if(res.data.success){
                Axios.get('https://albatroxmainapi.uuidesign.xyz/api/getAllusers').then((res)=>{
                    setUsers(res.data)
                })

                setModal2(false)
                setModal3(false)
                toast.success('User has been deleted')
            }
            else{
                toast.error('Something went wrong! Please contact support')
            }
            
        }).catch((err)=>{
            toast.error('Network connection error')
        })
    }
  return (
    <div className='storesWrapper'>
    <ToastContainer />
    <h4 className='storesTitle'>Users</h4>
    <hr />
    <div className="storesIntro">
        <Button onClick={toggle} color='success'>Add New user</Button>
    </div>
    <div className="storesTable">
    <Table id="table-to-xls" className="table">
    <thead>
      <tr>
        <th className='tHeading'>#</th>
        <th className='tHeading'>Name</th>
        
        <th className='tHeading'>Email</th>
        <th className='tHeading'>Phone</th>
        <th className='tHeading'>Role</th>

      </tr>
    </thead>
    <tbody>
      {users.map((val, index) => {
      
        return (
           
          <tr key={index} className='roow'>
           <td>{index+1}</td> 
           <td ><button onClick={toggle2} className='orderBtn' value={val.id}>{val.username}</button></td>
            <td>{val.useremail}</td>       
            <td>{val.userphone}</td> 
            <td>{val.role}</td>               
          </tr>
          
        );
      })}
    </tbody>
  </Table>
    </div>

    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>New User</ModalHeader>
        <div className="modalWrapper">
            <p style={{color:'red'}}>*Fill the details below. All fields are mandatory</p>
            <FloatingLabel className='formInput' onChange={(e)=>{setUsername(e.target.value)}} controlId="floatingInputGrid" label="User name">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g John Doe" />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setUseremail(e.target.value)}} controlId="floatingInputGrid" label="User email">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g johndoe@example.com" />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setUserphone(e.target.value)}} controlId="floatingInputGrid" label="User phoe">
                <Form.Control  className='inputSearch'  type="text" placeholder="e.g 0712345678" />
            </FloatingLabel>
            <FloatingLabel controlId="floatingInputGrid" label="Select role" className="selectMeter" >
                <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setUserrole(e.target.value)}} >
                    <option selected disabled>Select user role</option> 
                    <option value="Admin">Admin</option>  
                    <option value="Dispatch">Dispatch</option>    
                    <option value="Operations">Operations</option>    
                </Form.Select>
                    
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label="Store password">
                <Form.Control  className='inputSearch'  type="password" placeholder="password" />
            </FloatingLabel>
            <Button onClick={submitUser} color='info' className='modBtn'>Add user</Button>
        </div>

    </Modal>

    <Modal isOpen={modal2} toggle={toggle2}>
        <ModalHeader>Edit User</ModalHeader>
        <div className="modalWrapper">
            <p>Edit user</p>
            <FloatingLabel className='formInput' onChange={(e)=>{setUsername(e.target.value)}} controlId="floatingInputGrid" label={username}>
                <Form.Control  className='inputSearch'  type="text" placeholder={username} />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setUseremail(e.target.value)}} controlId="floatingInputGrid" label={useremail}>
                <Form.Control  className='inputSearch'  type="text" placeholder={useremail} />
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setUserphone(e.target.value)}} controlId="floatingInputGrid" label={userphone}>
                <Form.Control  className='inputSearch'  type="text" placeholder={userphone} />
            </FloatingLabel>
            <FloatingLabel controlId="floatingInputGrid" label="Select role" className="formInput" >
                <Form.Select className="selectMeter"  aria-label="Floating label select example" onChange={(e)=>{setUserrole(e.target.value)}} >
                    <option selected disabled>{userrole}</option> 
                    <option value="Admin">Admin</option>  
                    <option value="Dispatch">Dispatch</option>    
                    <option value="Operations">Operations</option>    
                </Form.Select>
                    
            </FloatingLabel>
            <FloatingLabel className='formInput' onChange={(e)=>{setPassword(e.target.value)}} controlId="floatingInputGrid" label={password}>
                <Form.Control  className='inputSearch'  type="password" placeholder={password} />
            </FloatingLabel>
            <div className="modButtons">
            <Button onClick={editUser} color='warning' className='modBtn'>Save changes</Button>
            <Button onClick={toggle3} color='danger' className='modBtn'>Remove user</Button>
            </div>
           
        </div>

    </Modal>

    <Modal isOpen={modal3} toggle={toggle3}>
        <ModalHeader>Remove User</ModalHeader>
        <div className="modalWrapper">
            <p>Are you sure you want to remove this user?</p>
            <div className="modButtons">
                <Button onClick={deleteUser} color='success' className='modBtn'>YES</Button>
                <Button color='danger' className='modBtn' onClick={toggle3}>NO</Button>
            </div>
        </div>

    </Modal>



</div>
  )
}
