import {React, useState, useEffect} from 'react'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './home.css'
import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar';
import GridViewIcon from '@mui/icons-material/GridView';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MopedIcon from '@mui/icons-material/Moped';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dashboard from '../../components/dashboard/Dashboard';
import Orders from '../../components/orders/Orders';
import Stores from '../../components/stores/Stores';
import Users from '../../components/users/Users';
import Riders from '../../components/riders/Riders';
import Track from '../../components/track/Track';
import Reports from '../../components/reports/Reports';
import Profile from '../../components/profile/Profile';

export default function Home() {

    const[email, setEmail] = useState('');
    const[username, setUsername] = useState('');
    const[userphone, setUserphone] = useState('');
    const[userrole, setUserrole] = useState('')
    const[password, setPassword] = useState('')
    const[active, setActive] = useState('')

    const navigate = useNavigate();

    Axios.defaults.withCredentials = true;
    let mail = ''
    useEffect(() =>{
      Axios.get('https://albatroxmainapi.uuidesign.xyz/api/profile').then((response)=>{
        if(response.data.valid){
            setEmail(response.data.email)
        }
        else{
          //pass
          navigate('/')
        }
      
      })
    },[])

    useEffect(()=>{
        Axios.get(`https://albatroxmainapi.uuidesign.xyz/api/getUserdetails?email=${email}`).then((res)=>{
            setUsername(res.data.username)
            setUserphone(res.data.userphone)
            setUserrole(res.data.role);
            setPassword(res.data.password);
            setActive(<Dashboard username={res.data.username} />)

        })

    },[email])

    const getOrders = () =>{
      setActive(<Orders />)
    }
    const getDashboard = () =>{
      setActive(<Dashboard username={username} />)
    }
    const getStores = () =>{
      setActive(<Stores />)
    }
    const getUsers = () =>{
      setActive(<Users />)
    }
    const getRiders = () =>{
      setActive(<Riders />)
    }
    const getTrack = () =>{
      setActive(<Track />)
    }
    const getReports = () =>{
      setActive(<Reports />)
    }
    const getProfile = () =>{
      setActive(<Profile username={username} userrole={userrole} email={email} password={password} userphone={userphone} />)
    }


  return (
    <div className='homeWrapper'>
        <Header />
    <div className='homeContainer'>
        <div className="sidebar">
            <div className='sidebarWrapper'>
              <img src="/assets/ab.png" className='sideLogo' alt="" />
            
              <hr />

                <div className="sidebarItems">
                    <button onClick={getDashboard}><GridViewIcon className='menuIcons' />Dashboard</button>
                    <button onClick={getOrders}><ShoppingCartIcon className='menuIcons' />Orders</button>
                    <button onClick={getStores}><StoreIcon className='menuIcons' />Stores</button>
                    <button onClick={getUsers}><PeopleAltIcon className='menuIcons' />Users</button>
                    <button onClick={getRiders}><MopedIcon className='menuIcons' />Riders</button>
                    
                    <button onClick={getTrack}><GpsFixedIcon className='menuIcons'/>Tracking</button>
                    <button onClick={getReports}><AssessmentIcon className='menuIcons' />Reports</button>
                    <button onClick={getProfile}><AccountCircleIcon className='menuIcons' />My Account</button>
                </div>
            </div>
        </div>
        <div className="maincontent">
            {active=='' && 
            <p>Loading...</p>
            }
            {active !=='' &&
              active
            }
            
        </div>

    </div>
    </div>
  )
}
