import {React, useState, useEffect }from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Button} from 'reactstrap'
import Form from 'react-bootstrap/Form';
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("")
    const navigate = useNavigate();

    Axios.defaults.withCredentials = true;
    let mail = ''
    useEffect(() =>{
      Axios.get('https://albatroxmainapi.uuidesign.xyz/api/profile').then((response)=>{
        if(response.data.valid){
        navigate('/home')
        }
        else{
          //pass
         
        }
      
      })
    },[])

    const login = ()=>{
        if(email != '' && password != ''){
            Axios.post('https://albatroxmainapi.uuidesign.xyz/api/login',{
                email,password
            }).then((res)=>{
                if(res.data.Login){
                    navigate('/home')
                }
                else{
                    toast.error('Store credentials do not match')
                }
            })
        }
        else{
            toast.error('All fields are required')
        }
    }


  return (
    <div className='loginWrapper'>
        <ToastContainer />
        <div className="loginContainer">
            <img src="/assets/ab.png" alt="logo" className='logo' />
            <h5>Albatrox Logistics</h5>
        

        <FloatingLabel className='formInput' onChange={(e)=>{setEmail(e.target.value)}} controlId="floatingInputGrid" label="User email">
              <Form.Control  className='inputSearch'  type="text" placeholder="User email" />
          </FloatingLabel>
          <FloatingLabel  onChange={(e)=>{setPassword(e.target.value)}} className='formInput' controlId="floatingInputGrid" label="User password">
              <Form.Control  className='inputSearch'  type="password" placeholder="User password" />
          </FloatingLabel>
          <div className="signinBtn">
            <Button onClick={login} className='loginBtn' color='success'>Sign in</Button>
            <a className='forgot' href="#">Forgot password?</a>
          </div>
          
            
        </div>

    </div>
  )
}
